import { Icon } from '@fv/client-components'
import { useBucketCounts } from '@/hooks/opportunities'

type NumberInBucketProps = {
  status: 'awarded' | 'confirmed' | 'offered' | 'open'
  className?: string
}

export const NumberInBucket = ({ status, className }: NumberInBucketProps) => {
  const countsQuery = useBucketCounts()
  const itemCount = countsQuery.data?.[status]

  return (
    <div
      className={`bg-fv-beer p-1 rounded-sm border-fv-gray border leading-none ml-2 min-w-6 text-center ${className}`}
      role="status"
    >
      {countsQuery.isLoading ? <Icon icon="spinner" /> : itemCount}
    </div>
  )
}
