import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { apiUri } from '@/constants'

type ShareProps = {
  email: string
  loadId: string
  all?: boolean
}

async function shareOpportunity({ all, email, loadId }: ShareProps) {
  const endpoint = `${apiUri}/loads/${loadId}/share`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ all, email }),
    method: 'POST',
  })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return email
  throw response.errorMessage
}

export function useShareOpportunity() {
  return useMutation(shareOpportunity, {
    onSuccess: email => {
      toast.success(`Opportunity shared with ${email}.`)
    },
  })
}
