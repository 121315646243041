import { useQuery } from '@tanstack/react-query'

import { carrierContextKeys } from '@/queryKeys'

import { type CarrierInfo } from '../../types/Context'
import { carrierFetch } from '../../utils/carrierFetch'
import { useUserContext } from '.'

async function fetchCarrierContext(carrierId: string) {
  return await carrierFetch<CarrierInfo>(`/carriers/${carrierId}`)
}

export function useCarrierContext() {
  const context = useUserContext()
  const carrierId = context.data?.user?.carrierId

  return useQuery(
    carrierContextKeys.id(carrierId),
    () => fetchCarrierContext(carrierId),
    {
      enabled: Boolean(carrierId),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  )
}
