import { useQuery } from '@tanstack/react-query'

import {
  type OpportunityBucketCountDto,
  type OpportunityBucketCountResponse,
} from '@fv/models'
import { useListingQueryParams } from '@/features/opportunities/hooks'
import { bucketCountKeys } from '@/queryKeys'
import { carrierFetch } from '@/utils/carrierFetch'

import { useAppFocus } from '../useAppFocus'

async function fetchBucketCounts({
  accountId,
}: OpportunityBucketCountDto): Promise<OpportunityBucketCountResponse> {
  return carrierFetch<OpportunityBucketCountResponse>(`/opportunities/counts`, {
    query: { accountId },
  })
}

export function useBucketCounts() {
  const isFocused = useAppFocus()
  const [{ accountId }] = useListingQueryParams()

  return useQuery(
    bucketCountKeys.account(accountId),
    () => fetchBucketCounts({ accountId }),
    {
      enabled: isFocused,
    },
  )
}
