import { useQuery } from '@tanstack/react-query'
import LogRocket from 'logrocket'

import { logrocketApp } from '@fv/client-core'
import { type CarrierUserContextDTO } from '@fv/models'
import { userContextKeys } from '@/queryKeys'

import { carrierFetch } from '../../utils/carrierFetch'

async function fetchUserContext() {
  return carrierFetch<CarrierUserContextDTO>(`/context`)
}

export function useUserContext(enabled = true) {
  return useQuery<CarrierUserContextDTO>(
    userContextKeys.all,
    fetchUserContext,
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess(data) {
        if (!logrocketApp) return

        const name = `${data.user.profile?.firstName ?? ''} ${
          data.user.profile?.lastName ?? ''
        }`.trim()

        LogRocket.identify(data.user._id, {
          ...(data.user.carrierId && { carrierId: data.user.carrierId }),
          email: data.user.email,
          ...(name && { name }),
        })
      },
    },
  )
}
