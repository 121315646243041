import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type OpportunityStatus } from '@fv/models'
import { useCacheHelpers } from '@/hooks/opportunities'

import { carrierFetch } from '../../../utils/carrierFetch'

interface DeclineOpts {
  loadId: string
}
async function declineOpportunity({ loadId }: DeclineOpts) {
  return await carrierFetch<DeclineOpts>(`/loads/${loadId}`, {
    method: 'DELETE',
  })
}

export function useDeclineOpportunity(status: OpportunityStatus) {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()

  return useMutation(declineOpportunity, {
    onSuccess: opportunity => {
      updateCachedOpportunity({ opportunity })
      removeFromList({
        loadId: opportunity.loadId,
        queryKey: ['opportunities', status],
      })

      toast.success('Opportunity declined successfully.')
    },
  })
}
