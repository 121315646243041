import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type LoadDocumentType } from '@fv/models'
import { apiUri } from '@/constants'
import { useCacheHelpers } from '@/hooks/opportunities'

interface UploadDocOpts {
  document: File
  documentType: LoadDocumentType
  loadId: string
}
async function uploadDocument({
  document,
  documentType, // 'bol' | 'signed-bol' | 'invoice' | 'proof-of-delivery'
  loadId,
}: UploadDocOpts) {
  const endpoint = `${apiUri}/loads/${loadId}/documents`
  const formData = new FormData()
  formData.append('document', document)

  const options = buildFetchOptionsWithAuth({
    body: formData,
    headers: { 'x-document-type': documentType },
    method: 'POST',
  })

  // Content-type set automatically from `formData`
  delete options.headers['Content-type']

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedOpportunity
  throw response.json.error
}

export function useUploadDocument() {
  const { updateCachedOpportunity } = useCacheHelpers()

  return useMutation(uploadDocument, {
    onSuccess: opportunity => {
      updateCachedOpportunity({
        listKeys: ['archived', 'confirmed'],
        opportunity,
      })

      toast.success('Document uploaded successfully.')
    },
  })
}
