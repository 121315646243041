import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createStore, useStore } from 'zustand'

import {
  type OppListingState,
  type OppListingStatus,
  type OppListingStore,
  type OppPage,
} from './types'

type OppListingStoreEntity = ReturnType<typeof createOppListingStore>
const createOppListingStore = (state?: Partial<OppListingState>) => {
  const defaultStore: OppListingState = {
    status: 'open',
    page: 'open',
    activeId: '',
    messagesOpen: false,
    ...state,
  }
  return createStore<OppListingStore>()(set => ({
    ...defaultStore,
    actions: {
      setMessagesOpen: messagesOpen => set({ messagesOpen }),
      setActiveId: activeId => set({ activeId }),
    },
  }))
}

const OppListingContext = createContext<OppListingStoreEntity | null>(null)

type Props = PropsWithChildren<{
  status?: OppListingStatus
  page: OppPage
}>
export const OppListingProvider = ({ children, status, page }: Props) => {
  const store = useRef(createOppListingStore({ status, page })).current
  useEffect(() => {
    store.setState({
      status,
      page,
    })
  }, [store, status, page])
  return (
    <OppListingContext.Provider value={store}>
      {children}
    </OppListingContext.Provider>
  )
}

export function useOppListingContext<T>(
  selector: (state: OppListingStore) => T,
): T {
  const store = useContext(OppListingContext)
  if (!store) throw new Error('Missing OppListingProvider in the tree')
  return useStore(store, selector)
}

export const useOppListingActions = () => {
  const store = useContext(OppListingContext)
  if (!store) throw new Error('Missing OppListingProvider in the tree')
  return useStore(store, s => s.actions)
}

export const useOppListingState = () => {
  return useOppListingContext(({ actions, ...state }) => state)
}
