import { MessagingPanel } from '../messaging/MessagingPage'
import {
  useOppListingActions,
  useOppListingState,
} from './OppListingContextProvider'
import { useOpportunities } from './queries'

export const OppMessaging = () => {
  const { messagesOpen, activeId, status } = useOppListingState()
  const { setMessagesOpen } = useOppListingActions()
  const listQuery = useOpportunities(status)
  return (
    <MessagingPanel
      isOpen={messagesOpen}
      close={() => setMessagesOpen(false)}
      opportunity={
        activeId ? listQuery.data?.find(d => d.loadId === activeId) : undefined
      }
    />
  )
}
