import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { LoadingPage } from './features/layout/LoadingPage'
import { NotFoundPage } from './features/layout/NotFoundPage'
import { ActivePage } from './features/opportunities/ActivePage'
import { DirectPage } from './features/opportunities/DirectPage'
import { OpenPage } from './features/opportunities/OpenPage'
import { QuotingPage } from './features/opportunities/QuotingPage'
import { WonPage } from './features/opportunities/WonPage'
import { SettingsPage } from './features/profile/SettingsPage'
import { SearchPage } from './features/search/SearchPage'
import { useCarrierLiveEvents } from './hooks/settings'

export const CarrierRoutes = ({ carrierId }: { carrierId: string }) => {
  useCarrierLiveEvents(carrierId)

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/" element={<Navigate to="opportunities" replace />} />

        <Route path="direct/:quoteRequestId" element={<DirectPage />} />

        <Route path="opportunities">
          <Route index element={<OpenPage />} />
          <Route path="declined" element={<OpenPage mode="declined" />} />
        </Route>

        <Route path="quoting">
          <Route index element={<QuotingPage />} />
          <Route path="lost" element={<QuotingPage mode="lost" />} />
        </Route>
        <Route path="won" element={<WonPage />} />

        <Route path="active">
          <Route index element={<ActivePage />} />
          <Route path="archived" element={<ActivePage mode="archived" />} />
        </Route>

        <Route path="search" element={<SearchPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}
