import clsx from 'clsx'
import { useEffect } from 'react'
import { useToggle } from 'usehooks-ts'

import { type Opportunity } from '../../types/Opportunity'
import { getBidExpiration } from '../../utils/getBidExpiration'
import {
  useOppListingActions,
  useOppListingState,
} from '../opportunities/OppListingContextProvider'
import {
  type OppListingStatus,
  type OppPage,
  oppPageStatusMap,
} from '../opportunities/types'
import { CardActions } from './CardActions'
import { ConfirmationForm } from './ConfirmationForm'
import { ExpirationNotice } from './ExpirationNotice'
import { QuoteForm } from './QuoteForm'
import { ShipmentDetails } from './ShipmentDetails'
import { ShipperCanceledOverlay } from './ShipperCanceledOverlay'

type Props = {
  isActive: boolean
  opportunity: Opportunity
}

const getPageFromStatus = (status: OppListingStatus): OppPage => {
  return Object.entries(oppPageStatusMap).find(([, statuses]) =>
    [...statuses].includes(status),
  )?.[0] as OppPage
}

const bidExpirationStatuses: OppListingStatus[] = ['offered', 'open']
export const OppCard = ({ isActive, opportunity }: Props) => {
  const { setActiveId } = useOppListingActions()
  const { status, page: storePage } = useOppListingState()
  const { bidExpirationDate, isExpired } = getBidExpiration(opportunity)
  const oppStatus = opportunity.status
  const [showQuoteForm, toggleQuoteForm, setShowQuoteForm] = useToggle()
  // 90% of the time page will not change depending on the status, so keep this in the store for cacheability
  const page =
    storePage === 'search' || storePage === 'direct'
      ? getPageFromStatus(oppStatus)
      : storePage

  useEffect(() => {
    if (showQuoteForm && !isActive) setShowQuoteForm(false)
  }, [isActive, showQuoteForm, setShowQuoteForm])
  return (
    <article
      className={clsx('shipment-item opacity-80 pb-6', {
        'shipment-item--selected border-fv-orange b1000:border-r border-r-0 !opacity-100':
          isActive,
      })}
      onClick={() => setActiveId(opportunity.loadId)}
    >
      {bidExpirationStatuses.includes(status) && bidExpirationDate && (
        <ExpirationNotice isActive={isActive} page={'opportunity'}>
          {isExpired ? 'Bidding expired on' : 'Bidding expires on '}{' '}
          {bidExpirationDate}
        </ExpirationNotice>
      )}
      <div className="shipment-item__initial">
        {page !== 'open' && <CardActions opportunity={opportunity} />}
        <ShipmentDetails opportunity={opportunity} />
        {page === 'open' && (
          <CardActions
            opportunity={opportunity}
            onToggleQuoteForm={toggleQuoteForm}
          />
        )}
      </div>

      {isActive && oppStatus === 'awarded' && (
        <ConfirmationForm opportunity={opportunity} />
      )}

      {(showQuoteForm ||
        (isActive && page === 'quoting' && status !== 'lost')) && (
        <QuoteForm opportunity={opportunity} hasQuoted={page === 'quoting'} />
      )}

      {opportunity.isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!opportunity.isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}
