import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { flattenPages, getNextPageParam } from '@fv/client-core'
import { type OpportunityFilterDTO, type OpportunitySort } from '@fv/models'
import { defaultPageSize } from '@/constants'
import { useAppFocus } from '@/hooks/useAppFocus'
import { opportunityQueryKeys, quoteRequestKeys } from '@/queryKeys'

import {
  type Opportunity,
  type PagedOpportunities,
} from '../../types/Opportunity'
import { carrierFetch } from '../../utils/carrierFetch'
import { type OppListingStatus } from './types'

async function fetchOpportunities({
  accountId,
  isArchived = false,
  limit = defaultPageSize,
  skip = 0,
  sortBy = 'pickupDate',
  sortDirection = 1,
  status,
}: Partial<OpportunityFilterDTO>): Promise<{
  data: Opportunity[]
  hasMore?: boolean
}> {
  return carrierFetch<PagedOpportunities>(`/opportunities`, {
    query: {
      accountId,
      isArchived,
      limit,
      skip,
      status,
      sortBy,
      sortDirection,
    },
  })
}

export interface OppListingParams {
  sortBy?: OpportunitySort
  sortDirection?: 1 | -1
  accountId?: string
}
export function useOpportunities(
  listName: OppListingStatus,
  params?: OppListingParams,
) {
  const isFocused = useAppFocus()
  const isArchived = listName === 'archived'
  const listQuery = useInfiniteQuery(
    opportunityQueryKeys.byFilter({
      status: listName,
      accountId: params?.accountId,
      sortBy: params?.sortBy,
      sortDirection: params?.sortDirection,
    }),
    ({ pageParam = 0 }) => {
      const status = isArchived ? 'confirmed' : listName

      return fetchOpportunities({
        isArchived,
        skip: pageParam,
        status,
        ...params,
      })
    },
    {
      enabled: isFocused && Boolean(listName),
      getNextPageParam,
    },
  )

  return {
    ...listQuery,
    data: flattenPages(listQuery.data?.pages),
  }
}

async function fetchQuoteRequest(quoteRequestId: string) {
  return carrierFetch<Opportunity[]>(`/opportunities/${quoteRequestId}`)
}

export function useQuoteRequest(quoteRequestId: string) {
  return useQuery(
    quoteRequestKeys.id(quoteRequestId),
    () => fetchQuoteRequest(quoteRequestId),
    {
      enabled: Boolean(quoteRequestId),
    },
  )
}
