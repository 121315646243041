import { useEffect } from 'react'

import { LoadMore } from '@fv/client-components'
import { EmptyList } from '@/components/shared/EmptyList'

import { OppCard } from '../opp-card/OppCard'
import { useListingQueryParams } from './hooks'
import { OppFilterSortControls } from './OppFilterSortControls'
import {
  useOppListingActions,
  useOppListingState,
} from './OppListingContextProvider'
import { useOpportunities } from './queries'

type Props = {
  emptyText: string
}
export const OppListing = ({ emptyText }: Props) => {
  const { activeId, status } = useOppListingState()
  const { setActiveId } = useOppListingActions()
  const [params] = useListingQueryParams()
  const listQuery = useOpportunities(status, params)
  const { data } = listQuery

  useEffect(() => {
    const prevItem = data?.find(d => d.loadId === activeId)
    if (!prevItem && data[0]?.loadId) {
      setActiveId(data[0]?.loadId)
    }
  }, [data, activeId, setActiveId])
  return (
    <main className="b1500:col-span-2 b1000:col-span-3 row-span-2">
      <OppFilterSortControls />

      {data?.length > 0 ? (
        data.map(opportunity => (
          <OppCard
            opportunity={opportunity}
            isActive={opportunity.loadId === activeId}
            key={opportunity.loadId}
          />
        ))
      ) : (
        <EmptyList message={emptyText} busy={listQuery.isLoading} />
      )}

      <LoadMore
        fetchNextPage={listQuery.fetchNextPage}
        hasNextPage={listQuery.hasNextPage}
        isLoading={listQuery.isFetchingNextPage}
      />
    </main>
  )
}
