import { type ChangeEvent, useState } from 'react'

import { Icon } from '@fv/client-components'
import { defaultOptions, fetchJson } from '@fv/client-core'
import { apiUri } from '@/constants'

import { LoginForm } from './LoginForm'

export const LoginMain = () => {
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({ email: '' })
  const [message, setMessage] = useState()
  const [showForm, setShowForm] = useState(true)

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    let value: string | boolean = e.target.value
    if (e.target.type === 'checkbox') {
      value = e.target.checked
    }
    setFields(f => ({ ...f, [name]: value }))
  }

  function onSubmit() {
    register().catch(e => {
      setMessage(e.toString())
    })
  }

  async function register() {
    setErrors({})
    const options = defaultOptions({
      cache: 'no-store',
      json: { email: fields.email },
      method: 'POST',
    })
    const result = await fetchJson(`${apiUri}/register`, options)
    if (!result.ok) return setMessage(result.errorMessage)
    if (result.status === 400) return setErrors(result.json?.errors || {})
    setShowForm(false)
  }

  return (
    <div>
      {message && (
        <div className="alert alert-danger">
          <code>{message}</code>
        </div>
      )}{' '}
      {showForm ? (
        <>
          <LoginForm
            onFieldChange={onFieldChange}
            fields={fields}
            errors={errors}
            onSubmit={onSubmit}
          />
          <div className="b600:ml-0 -ml-[1.75rem] mt-8 flex items-start gap-3 ">
            <Icon
              icon="hand-paper"
              className="color-warning"
              transform="down-4"
            />
            <p className="text-base leading-6">
              Enter the email address that your spot quote request was sent to.
              After you submit your email address, you will receive an email
              from notifications@freightview.com. In the email, simply click the
              link to automatically log you in to Freightview Carrier to start
              quoting freight.
            </p>
          </div>
        </>
      ) : (
        <div className="flex items-start gap-3 rounded-sm border bg-white p-4">
          <Icon icon="thumbs-up" transform="down-2" />
          <p className="mb-0 leading-6">
            Thank you - please check your email for the sign-in link to access
            Freightview Carrier.
          </p>
        </div>
      )}
    </div>
  )
}
