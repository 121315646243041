import clsx from 'clsx'
import { type PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

import { Nav } from './Nav'

export const AppLayout = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()

  return (
    <div
      className={clsx(
        'grid bg-[#f2f2f2] h-screen grid-cols-[17rem_1fr_26rem] grid-rows-[auto_auto_1fr] b1200:grid-cols-[17rem_1fr_22rem] b1000:grid-cols-1',
        {
          c2: pathname === '/settings',
        },
      )}
    >
      <Nav />
      {children}
      <div />
    </div>
  )
}
