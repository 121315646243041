import { Icon } from '@fv/client-components'
import { type EquipmentAccessorial } from '@fv/client-types'
import { useTypeNames } from '@/hooks/useTypeNames'

class Props {
  accessorial: EquipmentAccessorial
}

type AccessorialsProps = {
  accessorials?: EquipmentAccessorial[]
  isHazardous?: boolean | null
}

const AccessorialName = ({ accessorial }: Props) => {
  const { accessorialName } = useTypeNames()

  let label = ''
  if (accessorial.count) label += `${accessorial.count} `

  if (accessorial.key === 'temp') {
    const uom = `°${accessorial.uom?.toUpperCase()}`
    label += `${accessorial.min}${uom} to ${accessorial.max}${uom}`
  } else {
    label += accessorialName(accessorial.key)
  }

  if (accessorial.size) label += ` (${accessorial.size} ft.)`

  return ` / ${label}`
}

export const Accessorials = ({
  accessorials,
  isHazardous,
}: AccessorialsProps) => {
  if (!accessorials?.length && !isHazardous) return null

  return (
    <span>
      {' '}
      {isHazardous && '/ '}
      {isHazardous && <Icon icon="radiation-alt" className="color-secondary" />}
      {isHazardous && ' Hazardous'}
      {accessorials?.map(accessorial => {
        return AccessorialName({ accessorial })
      })}
    </span>
  )
}
