import clsx from 'clsx'
import dayjs from 'dayjs'

import { type PropsWithClassName } from '@fv/client-types'

type Props = PropsWithClassName & {
  value?: string
  type: 'origin' | 'stop' | 'destination'
}
export const DateDisplay = ({ className, type, value }: Props) => {
  if (!value)
    return (
      <span className="flex items-center self-stretch justify-center border border-fv-gray text-xs w-[3.8rem] flex-shrink-0 bg-fv-beer">
        No date
      </span>
    )

  const day = dayjs(value).format('DD')
  const month = dayjs(value).format('MMM')

  return (
    <div
      className={clsx(
        'flex w-[3.8rem] flex-shrink-0 self-stretch text-sm border-fv-orange border ',
        className,
        {
          '!border-[#99d87a]': type === 'destination',
          '!border-[#d4d4a3]': type === 'stop',
        },
      )}
    >
      <div className="bg-fv-beer flex-1 text-xs flex self-stretch justify-center">
        {month}
      </div>
      <div
        className={clsx(
          'bg-fv-orange-50 border-fv-orange border-l self-stretch flex items-center justify-center px-1',
          {
            '!border-[#99d87a] !bg-[#e4f6dc]': type === 'destination',
            '!border-[#d4d4a3] !bg-[#f1f1d2]': type === 'stop',
          },
        )}
      >
        {day}
      </div>
    </div>
  )
}
