import { useMutation, useQueryClient } from '@tanstack/react-query'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Quote } from '@fv/client-types'
import { type OpportunityStatus } from '@fv/models'
import { apiUri } from '@/constants'
import { useCacheHelpers } from '@/hooks/opportunities'
import {
  bucketCountKeys,
  messagesQueryKeys,
  opportunityQueryKeys,
  quoteRequestKeys,
} from '@/queryKeys'

type QuoteDTO = {
  amount: number
  currency: string
  equipmentType: string
  quoteId?: string
  quoteNum: string
}

async function submitQuotesForOpportunity({
  isUpdate,
  loadId,
  messageText,
  quotes,
}: {
  isUpdate?: boolean
  loadId: string
  messageText: string
  quotes: QuoteDTO[]
}) {
  const endpoint = `${apiUri}/loads/${loadId}/quotes`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      quotes,
      message: messageText,
    }),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)

  if (response.ok) {
    return {
      loadId,
      quotes: response.json as Quote[],
      updateCount: isUpdate ? quotes.length : 0,
    }
  }

  throw response.errorMessage
}

export function useSubmitQuotes(
  status: OpportunityStatus,
  quoteRequestId: string,
) {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()
  const queryClient = useQueryClient()

  return useMutation(submitQuotesForOpportunity, {
    onSuccess: ({ loadId, quotes, updateCount }) => {
      updateCachedOpportunity({
        opportunity: {
          loadId,
          quoteRequestId,
          status: 'offered',
        },
      })

      const isNewQuote = !updateCount

      if (isNewQuote) {
        removeFromList({
          loadId,
          queryKey: ['opportunities', status],
        })

        setTimeout(() => {
          queryClient.invalidateQueries(bucketCountKeys.all)
        })
      }

      queryClient.setQueryData(['quotes', loadId], quotes)

      const message = isNewQuote
        ? 'Quote added successfully.'
        : `${pluralize('Quote', updateCount)} updated successfully.`

      toast.success(message)

      setTimeout(() => {
        queryClient.invalidateQueries(messagesQueryKeys.load(loadId))
        queryClient.invalidateQueries(opportunityQueryKeys.search())
        queryClient.invalidateQueries(quoteRequestKeys.id(quoteRequestId))
      })
    },
  })
}
