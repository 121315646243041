import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useCacheHelpers } from '@/hooks/opportunities'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'

interface RejectOpts {
  loadId: string
}
async function rejectLoad({ loadId }: RejectOpts) {
  return await carrierFetch<Opportunity>(`/loads/${loadId}/reject`, {
    method: 'POST',
  })
}

export function useRejectLoad() {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()

  return useMutation(rejectLoad, {
    onSuccess: opportunity => {
      updateCachedOpportunity({ opportunity })

      removeFromList({
        loadId: opportunity.loadId,
        queryKey: ['opportunities', 'awarded'],
      })

      removeFromList({
        loadId: opportunity.loadId,
        queryKey: ['opportunities', 'confirmed'],
      })

      toast.success('Load declined successfully.')
    },
  })
}
