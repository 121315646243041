import { Icon } from '@fv/client-components'

export const LoadingPage = () => {
  return (
    <div className="grid bg-[#f2f2f2] h-screen grid-cols-[17rem_1fr_26rem]">
      <main>
        <h1>
          <Icon icon="spinner" />
        </h1>
      </main>
    </div>
  )
}
