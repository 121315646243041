import { type OpportunitySort } from '@fv/models'

import { type OppListingStatus } from './features/opportunities/types'

export const bucketCountKeys = {
  all: ['bucket-counts'] as const,
  account: (id?: string) =>
    !id ? bucketCountKeys.all : [...bucketCountKeys.all, 'account', id],
  byStartDate: (startDate: string) =>
    [...bucketCountKeys.all, startDate] as const,
}

interface OppListFilterKeyParams {
  status: OppListingStatus
  accountId?: string
  sortBy?: OpportunitySort
  sortDirection?: 1 | -1
}
export const opportunityQueryKeys = {
  all: ['opportunities'],
  search: () => [...opportunityQueryKeys.all, 'searchrResults'],
  byStatus: (status: OppListingStatus) => [...opportunityQueryKeys.all, status],
  byFilter: ({
    status,
    accountId,
    sortBy,
    sortDirection,
  }: OppListFilterKeyParams) => [
    ...opportunityQueryKeys.byStatus(status),
    accountId ?? '',
    sortBy ?? '',
    sortDirection ?? 1,
  ],
}

export const quoteRequestKeys = {
  all: ['quoteRequest'],
  id: (id: string) => [...quoteRequestKeys.all, id],
}

export const carrierContextKeys = {
  all: ['carrierContext'],
  id: (id: string) => [...carrierContextKeys.all, id],
}

export const userContextKeys = {
  all: ['userContext'],
}

export const messagesQueryKeys = {
  all: ['messages'],
  load: (loadId: string) => [...messagesQueryKeys.all, loadId],
}

export const quoteQueryKeys = {
  all: 'all',
  load: (loadId: string) => [...quoteQueryKeys.all, loadId],
}
