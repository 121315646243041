import { Icon, Tooltip } from '@fv/client-components'
import { usePrettyNumber } from '@/hooks/usePrettyNumber'

type SelectedPriceDisplayProps = {
  amount: number
  currency: string
  isLowest: boolean
  showComparison: boolean
}

export const SelectedPriceDisplay = ({
  amount,
  currency,
  isLowest,
  showComparison,
}: SelectedPriceDisplayProps) => {
  const prettyNumber = usePrettyNumber()

  const { dollars, cents } = dollarsAndCents({
    amount,
    prettyNumber,
  })

  return (
    <div className="flex text-[1.65rem]">
      {showComparison && (
        <Tooltip
          label={
            isLowest
              ? 'You have the lowest bid.'
              : 'Your bid is not the lowest.'
          }
        >
          <div
            className={`absolute -left-2 -top-2 flex items-center justify-center h-6 w-6 rounded-full border-2 bg-white ${isLowest ? 'border-[#99d87a] !bg-green-100' : 'border-red-400 !bg-red-100'}`}
          >
            <Icon
              className={`text-[1rem] ${isLowest ? 'text-[#99d87a]' : 'text-red-400'}`}
              icon={isLowest ? 'arrow-down' : 'arrow-up'}
            />
          </div>
        </Tooltip>
      )}
      <span className="flex items-start">
        $<span className="dollars">{dollars}</span>
        <div className="cents">{cents}</div>
        <div className="price__currency">{currency.toUpperCase()}</div>
      </span>
    </div>
  )
}

function dollarsAndCents({
  amount,
  prettyNumber,
}: {
  amount: number
  prettyNumber: (value: string) => string
}) {
  const [dollars, cents] = String(amount?.toFixed(2)).split('.')

  return {
    dollars: dollars ? prettyNumber(dollars) : '0',
    cents: cents ? cents.padEnd(2, '0') : '00',
  }
}
