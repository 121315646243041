import { useInfiniteQuery } from '@tanstack/react-query'

import { getNextPageParam } from '@fv/client-core'
import { defaultPageSize } from '@/constants'

import { type PagedOpportunities } from '../../types/Opportunity'
import { carrierFetch } from '../../utils/carrierFetch'

async function searchOpportunities({
  searchText,
  skip,
}: {
  searchText: string
  skip: number
}): Promise<PagedOpportunities> {
  return carrierFetch<PagedOpportunities>(`/opportunities/search`, {
    query: {
      limit: defaultPageSize,
      skip,
      searchText,
    },
  })
}

export function useOpportunitySearch(searchText: string) {
  return useInfiniteQuery(
    ['opportunities', 'searchResults', searchText],
    ({ pageParam = 0 }) => searchOpportunities({ searchText, skip: pageParam }),
    {
      enabled: Boolean(searchText),
      getNextPageParam,
    },
  )
}
