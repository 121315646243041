import { useSearchParams } from 'react-router-dom'

import { type OpportunitySort } from '@fv/models'
import { removeUndef } from '@fv/models/core'

import { type OppListingParams } from './queries'

type Func = (prev: OppListingParams) => OppListingParams

export const useListingQueryParams = (): [
  OppListingParams,
  (setter: Func) => void,
] => {
  const [params, setParams] = useSearchParams()
  const accountId = params.get('accountId')
  const sortBy = params.get('sortBy') as OpportunitySort
  const sortDir = params.get('sortDirection') === '-1' ? -1 : 1
  const listParams: OppListingParams = {
    ...(accountId && { accountId }),
    sortBy: sortBy || 'pickupDate',
    sortDirection: sortDir,
  }
  return [
    listParams,
    cb => {
      const newParams = cb(listParams)
      setParams(p => ({
        ...p,
        ...removeUndef(newParams),
      }))
    },
  ]
}
