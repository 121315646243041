import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  bucketCountKeys,
  opportunityQueryKeys,
  quoteRequestKeys,
} from '@/queryKeys'

import { carrierFetch } from '../../../utils/carrierFetch'

interface ArchiveOppArgs {
  carrierId: string
}
interface Response {
  shipmentArchivedCount: number
  loadArchivedCount: number
}
async function archiveCanceledOpportunities(body: ArchiveOppArgs) {
  return await carrierFetch<Response>(`/opportunities/canceled`, {
    method: 'DELETE',
    body,
  })
}

export function useArchiveCanceledOpportunities() {
  const queryClient = useQueryClient()

  return useMutation(archiveCanceledOpportunities, {
    onSuccess: () => {
      queryClient.invalidateQueries(opportunityQueryKeys.all)
      queryClient.invalidateQueries(quoteRequestKeys.all)
      toast.success('Removed canceled shipments successfully.')
      queryClient.invalidateQueries(bucketCountKeys.all)
    },
  })
}
