import { Icon, TextField } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

export const AdditionalTruckInfoForm = ({ className }: PropsWithClassName) => {
  return (
    <div className={`${className} flex items-center gap-x-2`}>
      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Carrier name
        </label>

        <TextField
          name="name"
          className="form-control"
          maxLength={50}
          type="text"
        />
      </div>
      <div className="form-group basis-1/4 mb-0">
        <label htmlFor="" className="label">
          Tracking
        </label>
        <div className="input-group input-group--flex ">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            name="tracking"
            className="form-control"
            maxLength={50}
            type="text"
          />
        </div>
      </div>
      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Truck
        </label>
        <div className="input-group input-group--flex">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            name="truck"
            className="form-control"
            maxLength={50}
            type="text"
          />
        </div>
      </div>

      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Seal
        </label>
        <div className="input-group input-group--flex ">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            name="seal"
            className="form-control"
            maxLength={50}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}
