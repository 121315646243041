import { type ChangeEvent, useEffect, useRef } from 'react'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'

type Props = {
  errors: any
  fields: any
  onFieldChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
}
export const LoginForm = ({
  errors,
  fields,
  onFieldChange,
  onSubmit,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <ValidatedForm onValidSubmit={onSubmit} className="">
      <label className="label">Send me a login link</label>
      <div className="input-group input-group--flex">
        <TextField
          ref={inputRef}
          name="email"
          type="email"
          error={errors.email}
          className="form-control"
          placeholder="Enter your email"
          onChange={onFieldChange}
          value={fields.email}
          required
          maxLength={200}
          autoFocus
        />

        <FvButton type="submit" theme="default" className="-ml-px">
          <Icon icon="check" />
          <span>Submit</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
