import { type ChangeEvent } from 'react'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'

type Props = {
  errors: any
  fields: any
  onFieldChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
}
export const VerifyForm = ({
  fields,
  errors,
  onFieldChange,
  onSubmit,
}: Props) => {
  return (
    <div>
      <ValidatedForm onValidSubmit={onSubmit}>
        <div className="form-group mb-0">
          <label className="label">
            Enter the code you were sent via email
          </label>
          <div className="input-group input-group--flex">
            <TextField
              name="token"
              type="text"
              className="form-control"
              placeholder="Enter your registration code"
              onChange={onFieldChange}
              value={fields.token}
              required
              error={errors.token}
              maxLength={100}
            />

            <FvButton type="submit" className="-ml-px" theme="default">
              <Icon icon="check" />
              <span>Submit</span>
            </FvButton>
          </div>
        </div>
      </ValidatedForm>
    </div>
  )
}
