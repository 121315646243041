import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import logo from '@fv/client-assets/freightview.svg'
import { Icon, useFvNavigate } from '@fv/client-components'
import { defaultOptions, fetchJson, setToken } from '@fv/client-core'
import { apiUri } from '@/constants'

import { PublicLayout } from '../layout/PublicLayout'
import exampleImage1 from './carrier-login-example-1.png'
import exampleImage2 from './carrier-login-example-2.png'
import { LoginMain } from './LoginMain'
import { VerifyPage } from './VerifyPage'

// TODO: Keep route params when redirecting
export const Login = () => {
  const { registrationToken } = useParams()
  const navigate = useFvNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const redirect = params.get('redirect')

  useEffect(() => {
    if (!registrationToken) return

    async function establishSession() {
      const options = defaultOptions({
        cache: 'no-store',
        json: { token: registrationToken },
        method: 'POST',
        credentials: 'include',
      })
      const result = await fetchJson(`${apiUri}/register/verify`, options)

      if (result.ok && result.json?.token) {
        setToken(result.json.token)
        navigate(redirect || '/', { replace: true })
      } else {
        navigate('/login', { replace: true })
      }
    }

    establishSession().catch(e => {
      console.error(e)
      navigate('/login', { replace: true })
    })
  }, [navigate, registrationToken, redirect])

  if (registrationToken) {
    return (
      <PublicLayout>
        <h1>
          <Icon icon="spinner" />
        </h1>
      </PublicLayout>
    )
  }

  return (
    <div className="flex h-screen flex-col items-stretch">
      <header className="relative flex items-center justify-center gap-4 border-b border-[#e3e3e3] bg-[#f9f9f9] px-8 py-4 text-center">
        <img
          src={logo}
          alt="Freightview"
          className="apect-auto inline-block w-16"
        />

        <span className="b600:hidden text-lg">
          Log in to Freightview Carrier to start quoting freight
        </span>
        <span className="b600:block hidden">Log in to Freightview Carrier</span>
      </header>
      <div className="b1150:flex-wrap relative flex items-stretch">
        <div className="b1300:pl-20 b600:px-8 b1150:py-12 b1150:basis-full basis-1/2 bg-[#F2F9FC] p-20 pl-48">
          <div className="b1150:max-w-lg b1150:m-auto">
            <LoginMain />
          </div>
        </div>

        <div className="b1150:top-auto b1150:bottom-[9rem] b1150:p-3 b1150:border-[.2rem] b1150:text-sm absolute  left-1/2 top-[5.9rem] -translate-x-1/2 rounded-full border-[.4rem] border-[#c1e7fd] bg-white p-4 text-xl">
          OR
        </div>

        <div className="b1300:pr-20 b1150:py-12 b1150:border-t b600:px-8 b1150:basis-full basis-1/2 border-l border-[#d1e6ff] bg-[#edfaff] p-20 pr-48">
          <div className="b1150:max-w-lg b1150:m-auto">
            <VerifyPage />
          </div>
        </div>
      </div>
      <div className="b1450:flex-wrap b1000:px-12 b1200:px-32 b1450:px-60 b1450:max-h-none flex flex-1 items-start gap-4 border-t border-[#d1e6ff] bg-[#e1f6ff] px-20 py-20">
        <div className="b1450:basis-full basis-1/2">
          <div className="mb-4 inline-block rounded-md bg-[#f3fcff] p-4 text-xs italic">
            Freightview Carrier example screenshot
          </div>
          <div className="element-fade max-h-[13rem] overflow-hidden border-[.75rem] border-b-0 border-white">
            <img src={exampleImage1} alt="example 1" className="" />
          </div>
        </div>
        <div className="b1450:basis-full b1450:mt-8 basis-1/2">
          <div className="mb-4 inline-block rounded-md bg-[#f3fcff] p-4 text-xs italic">
            Freightview Carrier example screenshot
          </div>
          <div className="element-fade max-h-[13rem] overflow-hidden border-[.75rem] border-b-0 border-white">
            <img src={exampleImage2} alt="example 2" />
          </div>
        </div>
      </div>
    </div>
  )
}
