import { type ChangeEvent, useEffect, useRef, useState } from 'react'

import { FvButton, TextField, ValidatedForm } from '@fv/client-components'
import { supportMessage } from '@/constants'
import { useCarrierContext, useUserContext } from '@/hooks/settings'

import { useUpdateProfile } from './useUpdateProfile'

interface Errors {
  server?: string
  firstName?: string
  lastName?: string
  role?: string
  carrierName?: string
}
export const ProfileForm = () => {
  const carrierContext = useCarrierContext()
  const updateProfile = useUpdateProfile()
  const userContext = useUserContext()
  const user = userContext.data?.user

  const formRef = useRef<HTMLFormElement>(null)
  const [errors, setErrors] = useState<Errors>({})
  const [fields, setFields] = useState({
    carrierName: '',
    firstName: '',
    lastName: '',
    role: '',
  })

  async function onSaveProfile() {
    updateProfile
      .mutateAsync({
        carrierName: fields.carrierName,
        profile: {
          firstName: fields.firstName,
          lastName: fields.lastName,
          role: fields.role,
        },
      })
      .then(() => formRef.current?.reset())
      .catch(e => {
        setErrors({
          server: e?.message || `Unable to update profile, ${supportMessage}`,
        })
      })
  }

  function onFieldChange(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) {
    const name = e.target.name
    const value = e.target.value
    setFields(f => ({ ...f, [name]: value }))
  }

  useEffect(() => {
    if (!user) return
    setFields(f => ({
      ...f,
      firstName: user.profile?.firstName || '',
      lastName: user.profile?.lastName || '',
      role: user.profile?.role || '',
    }))
  }, [user])

  return (
    <ValidatedForm
      ref={formRef}
      className="flex items-end gap-x-2 mb-8 b800:grid b800:grid-cols-2 b800:gap-y-4 b450:grid-cols-1"
      onValidSubmit={onSaveProfile}
    >
      {errors.server && (
        <div className="alert alert-danger">{errors.server}</div>
      )}

      {!carrierContext.data?.name && (
        <div>
          <label className="label" htmlFor="carrierName">
            Carrier name
          </label>
          <TextField
            id="carrierName"
            name="carrierName"
            error={errors.carrierName}
            className="form-control"
            required
            value={fields.carrierName}
            onChange={onFieldChange}
            disabled={updateProfile.isLoading}
          />
        </div>
      )}

      <div>
        <label className="label" htmlFor="firstName">
          First name
        </label>
        <TextField
          id="firstName"
          name="firstName"
          error={errors.firstName}
          className="form-control"
          required
          value={fields.firstName}
          onChange={onFieldChange}
          disabled={updateProfile.isLoading}
        />
      </div>
      <div className="basis-60">
        <label className="label" htmlFor="lastName">
          Last name
        </label>
        <TextField
          id="lastName"
          name="lastName"
          error={errors.lastName}
          className="form-control"
          required
          value={fields.lastName}
          onChange={onFieldChange}
          disabled={updateProfile.isLoading}
        />
      </div>
      <div>
        <label className="label" htmlFor="role">
          Role
        </label>
        <select
          className="form-control form-control--select"
          id="role"
          name="role"
          value={fields.role}
          onChange={onFieldChange}
          required
          disabled={updateProfile.isLoading}
        >
          <option />
          <option>Manager</option>
          <option>Dispatcher</option>
          <option>Driver</option>
          <option>Sales Representative</option>
          <option>Other</option>
        </select>
      </div>

      <FvButton
        type="submit"
        disabled={updateProfile.isLoading}
        theme="default"
        icon={updateProfile.isLoading ? 'spinner' : 'user-check'}
      >
        <span>Save</span>
      </FvButton>

      <input
        type="hidden"
        name="role"
        value={fields.role}
        onChange={onFieldChange}
      />
    </ValidatedForm>
  )
}
