import clsx from 'clsx'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  FvButton,
  Icon,
  TextAreaField,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { AdditionalTruckInfoForm } from '@/components/shared/AdditionalTruckInfoForm'
import { supportMessage } from '@/constants'

import { type Opportunity } from '../../types/Opportunity'
import { useConfirmLoad } from './hooks/useConfirmLoad'
import { useRejectLoad } from './hooks/useRejectLoad'

type Props = {
  opportunity: Opportunity
}

export const ConfirmationForm = ({ opportunity }: Props) => {
  const { isLegacy, loadId, status } = opportunity
  const formRef = useRef()
  const confirmLoad = useConfirmLoad()
  const rejectLoad = useRejectLoad()
  const disabled = confirmLoad.isLoading || rejectLoad.isLoading

  // mg mockup ticket 23684 and 23685
  const [showAdditionalInfo] = useState(false)
  // end

  const [values, setValues] = useState({
    message: '',
    pickupNumber: '',
  })

  function onValidSubmit() {
    if (disabled) return

    confirmLoad
      .mutateAsync({
        loadId,
        message: values.message,
        pickupNumber: values.pickupNumber,
      })
      .catch(() => {
        toast.error(`Unable to confirm load, ${supportMessage}`)
      })
  }

  function rejectAwardedLoad() {
    if (disabled) return

    if (
      window.confirm(
        'By rescinding your winning bid, you acknowledge you will no longer be taking this freight. The shipper will be notified and you will not be assigned to this particular load.',
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit} ref={formRef}>
      <div className="shipment-item-ancillary">
        <div className="grid grid-cols-12 gap-x-2 mt-6 mb-2 b550:mb-4 b550:[&>*]:col-span-12">
          <div className="form-group col-span-4">
            <label htmlFor="" className="label">
              Confirmation
              <span className="text-fv-orange ml-1">*</span>
            </label>
            <div className="input-group input-group--flex ">
              <div className="input-group-addon">
                <Icon icon="hashtag" className="color-dark" />
              </div>
              <TextField
                className="form-control"
                disabled={disabled || status === 'confirmed'}
                maxLength={50}
                name="pickupNumber"
                onChange={e => {
                  setValues(prev => ({
                    ...prev,
                    pickupNumber: e.target.value,
                  }))
                }}
                required
                type="text"
                value={values.pickupNumber}
              />
            </div>
          </div>

          <div className="form-group col-span-8 mb-0">
            <label htmlFor="" className="label">
              Message
            </label>
            <TextAreaField
              className="form-control"
              disabled={disabled}
              maxLength={500}
              name="message"
              onChange={e => {
                setValues(prev => ({
                  ...prev,
                  message: e.target.value,
                }))
              }}
              placeholder="Include an optional message here."
              value={values.message}
            />
          </div>
        </div>
        {/* mg mockup ticket 23684 and 23685 */}
        {showAdditionalInfo && (
          <AdditionalTruckInfoForm className="-mt-2 mb-6" />
        )}
        {/* end */}

        <div className="flex gap-x-4 b550:gap-x-1">
          {/* mg mockup ticket 23684 and 23685 */}
          {/* <FvButton
            icon={showAdditionalInfo ? 'minus' : 'plus'}
            onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
          >
            <span className="b550:hidden">Add truck info (optional)</span>
            <span className="hidden b550:inline-block">Truck info</span>
          </FvButton> */}
          {/* end */}
          <div className="border-t h-px border-dotted flex-1 border-fv-gray" />
          <div className="ml-auto">
            {isLegacy === false && (
              <FvButton
                disabled={disabled}
                onClick={rejectAwardedLoad}
                type="button"
                theme="default"
                icon={rejectLoad.isLoading ? 'spinner' : 'ban'}
              >
                <span>Decline</span>
              </FvButton>
            )}

            <FvButton
              type="submit"
              disabled={disabled}
              theme="default"
              icon={confirmLoad.isLoading ? 'spinner' : 'shield-check'}
              iconClass={clsx({ 'color-success': !confirmLoad.isLoading })}
            >
              <span>Confirm</span>
            </FvButton>
          </div>
        </div>
      </div>
    </ValidatedForm>
  )
}
