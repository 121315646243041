import clsx from 'clsx'

import { SelectField, type SelectFieldProps } from '@fv/client-components'

export const SelectInput = (props: SelectFieldProps) => {
  const { className, ...rest } = props
  return (
    <SelectField
      className={clsx('form-control form-control--select', className)}
      {...rest}
    />
  )
}
