import { ProfileForm } from './ProfileForm'

export const ProfileModalView = () => (
  <div className="user-info-first-time-ctn">
    <p>
      Please fill out your first name, last name and your job role. You only
      have to do this once.
    </p>
    <ProfileForm />
  </div>
)
