import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import { type Message } from '@fv/models'
import { supportMessage } from '@/constants'
import { useUserContext } from '@/hooks/settings'
import { messagesQueryKeys } from '@/queryKeys'

import { carrierFetch } from '../../../utils/carrierFetch'

interface SendMessageArgs {
  messageText: string
  onSuccess?: (msg: Message) => void
}

interface Args {
  loadId: string
  messageText: string
}
async function postMessage({ loadId, messageText }: Args) {
  return carrierFetch<Message>(`/loads/${loadId}/messages`, {
    body: { messageText },
    method: 'POST',
  })
}

export function useSendMessage(loadId: string) {
  const queryClient = useQueryClient()
  const userQuery = useUserContext()
  const { firstName, lastName } = userQuery.data?.user.profile || {}
  const name = `${firstName} ${lastName}`

  const onMutate = useCallback(
    (dto: Args) => {
      queryClient.setQueryData<Message[]>(
        messagesQueryKeys.load(dto.loadId),
        (prev = []) =>
          prev.concat({
            _id: 'newMessage',
            accountId: '',
            isLegacy: false,
            loadId: dto.loadId,
            quoteRequestId: '',
            subType: 'message',
            type: 'spot-quote',
            createdDate: new Date().toISOString(),
            participants: [
              {
                name,
                isAuthor: true,
                role: 'carrier',
              },
            ],
            messageText: dto.messageText,
          }),
      )
    },
    [name, queryClient],
  )

  const mutation = useMutation(postMessage, { onMutate })

  const sendMessage = useCallback(
    ({ messageText, onSuccess }: SendMessageArgs) => {
      mutation
        .mutateAsync({ loadId, messageText })
        .then(message => {
          onSuccess && onSuccess(message)
        })
        .catch(() => {
          toast.error(`Unable to send message, ${supportMessage}`)
          queryClient.setQueryData<Message[]>(
            messagesQueryKeys.load(loadId),
            (prev = []) => prev.filter(m => m._id !== 'newMessage'),
          )
        })
    },
    [loadId, mutation, queryClient],
  )

  return { sendMessage, isSendingMessage: mutation.isLoading }
}
