import { type ChangeEvent } from 'react'

import { FvButton, TextField, ValidatedForm } from '@fv/client-components'

type Props = {
  isLoading?: boolean
  onFieldChange: (e: ChangeEvent<HTMLInputElement>) => void
  onValidSubmit: () => void
  value?: string
}
export const SearchForm = ({
  isLoading = false,
  onFieldChange,
  onValidSubmit,
  value,
}: Props) => {
  return (
    <ValidatedForm className="mb-6" onValidSubmit={onValidSubmit}>
      <div className="input-group input-group--flex">
        <TextField
          autoFocus
          className="form-control"
          disabled={isLoading}
          maxLength={200}
          name="search"
          onChange={onFieldChange}
          placeholder="Company name, quote #, shipper ref #, p/o #"
          required
          value={value}
        />
        <FvButton
          theme="default"
          icon={isLoading ? 'spinner' : 'search'}
          disabled={isLoading}
          className="-ml-px"
          type="submit"
        >
          <span>Search</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
