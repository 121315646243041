import { useParams } from 'react-router-dom'

import { type OpportunityStatus } from '@fv/models'
import { EmptyList } from '@/components/shared/EmptyList'

import { type Opportunity } from '../../types/Opportunity'
import { MessagingPanel } from '../messaging/MessagingPage'
import { OppCard } from '../opp-card/OppCard'
import { OppFilterPanel } from './FilterPanel'
import {
  OppListingProvider,
  useOppListingState,
} from './OppListingContextProvider'
import { OppMessaging } from './OppMessaging'
import { useQuoteRequest } from './queries'

type StatusCountKey = OpportunityStatus | 'canceled'

export const DirectPage = () => {
  const { quoteRequestId } = useParams()
  const listQuery = useQuoteRequest(quoteRequestId)

  if (listQuery.isError) {
    return (
      <>
        <main className="b1500:col-span-2 b1000:col-span-3 row-span-2">
          <EmptyList message="Sorry, something went wrong. Please double check your direct link or contact support if the issue persists." />
        </main>
        <MessagingPanel />
      </>
    )
  }

  return (
    <OppListingProvider page="direct">
      <OppFilterPanel items={[]} />
      <main className="b1500:col-span-2 b1000:col-span-3 row-span-2">
        {listQuery.data?.length > 0 ? (
          <DirectResults opportunities={listQuery.data ?? []} />
        ) : (
          <EmptyList
            busy={listQuery.isLoading}
            message="No loads found for this opportunity."
          />
        )}
      </main>
      <OppMessaging />
    </OppListingProvider>
  )
}

type ResultsProps = {
  opportunities: Opportunity[]
}
export const DirectResults = ({ opportunities }: ResultsProps) => {
  const { activeId } = useOppListingState()
  const statusCounts: Partial<Record<StatusCountKey, number>> = {}

  opportunities.forEach(opp => {
    const status = opp.isCanceled ? 'canceled' : opp.status
    statusCounts[status] = (statusCounts[status] ?? 0) + 1
  })

  const heading = Object.keys(statusCounts)
    .map(k => `${statusCounts[k as StatusCountKey]} ${k}`)
    .join(', ')
    .concat(` load${opportunities.length > 1 ? 's' : ''} for this opportunity.`)

  return (
    <>
      {opportunities.length > 0 && (
        <header className="header-divider">{heading}</header>
      )}
      {opportunities.map(o => (
        <OppCard
          isActive={activeId === o.loadId}
          opportunity={o}
          key={o.loadId}
        />
      ))}
    </>
  )
}
