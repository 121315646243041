import { type ChangeEvent, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, SelectField } from '@fv/client-components'
import { acceptedFileTypes } from '@fv/client-core'
import { type LoadDocumentType } from '@fv/models'
import { documentTypes, supportMessage } from '@/constants'
import { usePrettyNumber } from '@/hooks/usePrettyNumber'

import { useUploadDocument } from './hooks/useUploadDocument'

const fileInputStyle = { display: 'none' }
const UPLOAD_LIMIT_BYTES = 52428800 // 50 MB

type Values = {
  description: string
  documentType: LoadDocumentType | ''
}

type Props = {
  onClose: () => void
  opportunity: {
    loadId: string
  }
}

export const UploadDocumentField = ({ onClose, opportunity }: Props) => {
  const { loadId } = opportunity
  const fileInputRef = useRef<HTMLInputElement>(null)
  const prettyNumber = usePrettyNumber()
  const uploadDocument = useUploadDocument()

  const [error, setError] = useState(null)
  const [values, setValues] = useState<Values>({
    description: '',
    documentType: '',
  })

  function fileInputChanged(e: ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files
    if (fileList.length === 0) return

    const totalFileSize = Array.from(fileList).reduce(
      (total, f) => total + f.size,
      0,
    )

    if (totalFileSize > UPLOAD_LIMIT_BYTES) {
      toast.error(
        `Please choose a file that is smaller than the limit of ${prettyNumber(
          UPLOAD_LIMIT_BYTES / 1024,
        )} KB`,
      )

      // Remove file from input
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }

      return
    }

    const file = fileList[0]
    setValues(prev => ({
      ...prev,
      description: `${file.name} - ${prettyNumber(file.size)} bytes`,
    }))
    uploadSelectedDocument(file)
  }

  function selectFileClicked() {
    if (values.documentType) fileInputRef.current.click()
    else setError('Document type required')
  }

  async function uploadSelectedDocument(document: File) {
    uploadDocument
      .mutateAsync({
        document,
        documentType:
          values.documentType === '' ? 'other' : values.documentType,
        loadId,
      })
      .then(onClose)
      .catch(() => {
        toast.error(`Unable to upload document, ${supportMessage}`)
        fileInputRef.current.value = ''
        setValues(prev => ({
          ...prev,
          description: '',
        }))
      })
  }

  const setDocumentType = (e: ChangeEvent<HTMLSelectElement>) => {
    setValues(prev => ({
      ...prev,
      documentType: e.target.value as LoadDocumentType,
    }))
  }

  const isUploading = uploadDocument.isLoading
  const options = documentTypes.slice(values.documentType ? 1 : 0)

  return (
    <>
      <SelectField
        className="form-control form-control--select form-control--upload-choose-type"
        disabled={isUploading}
        error={error}
        id="documentType"
        name="documentType"
        onChange={setDocumentType}
        options={options}
        value={values.documentType}
      />
      <div className="upload-document-file-ctn">
        <div className="input-group input-group--flex">
          <input
            className="form-control disabled"
            disabled
            name="description"
            placeholder="i.e. doc.pdf, doc.png, or doc.jpg"
            readOnly
            type="text"
            value={values.description}
          />
          <input
            accept={acceptedFileTypes}
            name="document"
            onChange={fileInputChanged}
            ref={fileInputRef}
            style={fileInputStyle}
            type="file"
          />
          <FvButton
            className="-ml-px"
            theme="default"
            disabled={isUploading}
            onClick={selectFileClicked}
            type="button"
            icon={isUploading ? 'spinner' : 'cloud-upload-alt'}
          >
            <span>{isUploading ? 'Uploading...' : 'Choose file'}</span>
          </FvButton>
        </div>
      </div>
    </>
  )
}
