import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Quote } from '@fv/client-types'
import { apiUri } from '@/constants'
import { quoteQueryKeys } from '@/queryKeys'

async function fetchQuotes(loadId: string): Promise<Quote[]> {
  const endpoint = `${apiUri}/loads/${loadId}/quotes`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

function selectQuotes(quotes: Quote[]) {
  const availableQuotes: Quote[] = []
  const manualRates = quotes.filter(q => q.method === 'manual')

  const apiRate = quotes
    .filter(q => q.method === 'api')
    .sort((a, b) => a.amount - b.amount)[0]

  const contractedRate = quotes
    .filter(q => q.method === 'upload')
    .sort((a, b) => a.amount - b.amount)[0]

  const carrierRate = manualRates
    .filter(q => q.source === 'carrier')
    .sort((a, b) => a.amount - b.amount)[0]

  const shipperRate = manualRates
    .filter(q => q.source === 'shipper')
    .sort((a, b) => b.createdDate.localeCompare(a.createdDate))[0]

  apiRate && availableQuotes.push(apiRate)
  contractedRate && availableQuotes.push(contractedRate)
  carrierRate && availableQuotes.push(carrierRate)
  shipperRate && availableQuotes.push(shipperRate)

  return availableQuotes.sort((a, b) => a.amount - b.amount)
}

export function useQuotes(loadId: string) {
  return useQuery(quoteQueryKeys.load(loadId), () => fetchQuotes(loadId), {
    enabled: Boolean(loadId),
    select: selectQuotes,
  })
}
