import { OppFilterPanel } from './FilterPanel'
import { OppListing } from './OppListing'
import { OppListingProvider } from './OppListingContextProvider'
import { OppMessaging } from './OppMessaging'

export const WonPage = () => {
  return (
    <OppListingProvider status={'awarded'} page="won">
      <OppFilterPanel
        items={[{ label: 'Awaiting confirmation', icon: 'trophy', to: '/won' }]}
      />
      <OppListing emptyText={'There are no won shipments in your list.'} />
      <OppMessaging />
    </OppListingProvider>
  )
}
