import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type LoadDocument } from '@fv/client-types'
import { useCacheHelpers } from '@/hooks/opportunities'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'

interface DeleteOpts {
  document: LoadDocument
  loadId: string
}
async function deleteDocument({ document, loadId }: DeleteOpts) {
  return await carrierFetch<Opportunity>(
    `/loads/${loadId}/documents/${document._id}`,
    {
      method: 'DELETE',
    },
  )
}

export function useDeleteDocument() {
  const { updateCachedOpportunity } = useCacheHelpers()

  return useMutation(deleteDocument, {
    onSuccess: opportunity => {
      updateCachedOpportunity({
        listKeys: ['archived', 'confirmed'],
        opportunity,
      })

      toast.success('Document deleted successfully.')
    },
  })
}
