import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { apiUri } from '@/constants'

interface FetchOptions {
  query?: Record<string, any>
  body?: Record<string, any>
  headers?: Record<string, any>
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
}
export async function carrierFetch<T>(
  path: string,
  options?: FetchOptions,
): Promise<T> {
  const endpoint = `${apiUri}${path}`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({
      method: 'GET',
      ...options,
      ...(options?.body && { body: JSON.stringify(options.body) }),
    }),
  )
  if (response.ok) return response.json
  throw response.errorMessage
}

export async function adminFetchRaw(uri: string, options?: RequestInit) {
  return fetch(`${apiUri}${uri}`, {
    ...buildFetchOptionsWithAuth(),
    ...options,
  })
}
