import { Icon } from '@fv/client-components'

type Props = {
  busy?: boolean
  message: string
}
export const EmptyList = ({ busy, message }: Props) => (
  <div className="box box--help box--help-gradient">
    <p className="icon-message mb-0">
      <Icon
        icon={busy ? 'spinner' : 'hand-paper'}
        className="fa-fw color-dark"
      />
      {busy ? 'Loading...' : message}
    </p>
  </div>
)
