import { useEffect, useState } from 'react'

import { Modal, OlarkChat } from '@fv/client-components'

import { AppLayout } from './features/layout/AppLayout'
import { ProfileModalView } from './features/profile/ProfileModalView'
import { useUserContext } from './hooks/settings'
import { CarrierRoutes } from './CarrierRoutes'
import { adminUri } from './constants'

export const AuthenticatedApp = () => {
  const context = useUserContext()
  const [hasProfile, setHasProfile] = useState(true)
  const { _id: userId, carrierId, email, profile } = context.data?.user ?? {}
  const { firstName, lastName, role } = profile ?? {}

  useEffect(() => {
    if (context.isLoading) return
    setHasProfile(
      Boolean(firstName?.trim() && lastName?.trim() && role?.trim()),
    )
  }, [context.isLoading, firstName, lastName, role])

  return (
    <>
      <AppLayout>
        <CarrierRoutes carrierId={carrierId} />
      </AppLayout>

      {!hasProfile && (
        <Modal>
          <ProfileModalView />
        </Modal>
      )}
      <OlarkChat
        carrierId={carrierId}
        email={email}
        linkLabel="View as customer"
        name={
          firstName && lastName ? `${firstName} ${lastName}` : 'Unknown user'
        }
        userId={userId}
        viewAsLink={`${adminUri}/search/${userId}`}
      />
    </>
  )
}
