import { FvButton } from '@fv/client-components'

type Props = {
  onClick?: () => void
}
export const ModalCloseButton = ({ onClick }: Props) => (
  <FvButton
    type="button"
    className="!absolute right-0 top-0"
    theme="primary"
    onClick={onClick}
    icon="times"
  >
    <span>Close</span>
  </FvButton>
)
