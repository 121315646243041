import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type OpportunityStatus } from '@fv/models'
import { useCacheHelpers } from '@/hooks/opportunities'
import { bucketCountKeys, opportunityQueryKeys } from '@/queryKeys'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'
import { getStartDateForStatus } from '../../../utils/getStartDateForStatus'

interface ArchiveOpts {
  archive: boolean
  loadId: string
}

async function archiveOpportunity({ archive, loadId }: ArchiveOpts) {
  return carrierFetch<Opportunity>(`/loads/${loadId}/archive`, {
    body: { archive },
    method: 'PUT',
  })
}

// Used for both archive/unarchive via the `archive` flag
export function useArchiveOpportunity(status: OpportunityStatus) {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()
  const queryClient = useQueryClient()

  return useMutation(archiveOpportunity, {
    onSuccess: opportunity => {
      const { isArchived, loadId } = opportunity
      const message = `Shipment ${
        isArchived ? 'archived' : 'unarchived'
      } successfully.`

      updateCachedOpportunity({ opportunity })
      removeFromList({
        loadId,
        queryKey: ['opportunities', isArchived ? status : 'archived'],
      })

      toast.success(message)

      setTimeout(() => {
        queryClient.invalidateQueries(opportunityQueryKeys.all)

        queryClient.invalidateQueries(
          bucketCountKeys.byStartDate(getStartDateForStatus(status)),
        )
      }, 1000)
    },
  })
}
