import dayjs from 'dayjs'

import { type Opportunity } from '../types/Opportunity'

export const getBidExpiration = (opportunity: Opportunity) => {
  const bidExpDate = opportunity?.bidExpiration?.utc
    ? dayjs.utc(opportunity.bidExpiration.utc)
    : undefined

  if (!bidExpDate) {
    return {
      isExpired: false,
    }
  }

  return {
    bidExpirationDate: bidExpDate.local().format('ddd MMM D / h:mm A'),
    isExpired: dayjs.utc().isAfter(bidExpDate),
  }
}
