import { Icon } from '@fv/client-components'

type Props = {
  openMessages: () => void
}

export const MessageFlyOut = ({ openMessages }: Props) => {
  return (
    <a
      className="standard-link standard-link--message-flyout"
      onClick={openMessages}
    >
      <Icon icon="comment-alt-lines" transform="down-.5" />
      <span className="messages-text">Messages</span>
    </a>
  )
}
