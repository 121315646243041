import { type ChangeEvent, useState } from 'react'

import { useFvNavigate } from '@fv/client-components'
import { defaultOptions, fetchJson, setToken } from '@fv/client-core'
import { apiUri } from '@/constants'

import { VerifyForm } from './VerifyForm'

export const VerifyPage = () => {
  const navigate = useFvNavigate()
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({ token: '' })
  const [message, setMessage] = useState()

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    let value: string | boolean = e.target.value
    if (e.target.type === 'checkbox') {
      value = e.target.checked
    }
    setFields(f => ({ ...f, [name]: value }))
  }

  function onSubmit() {
    verifyRegistration().catch(e => {
      console.error(e)
      setMessage(e.message)
    })
  }

  async function verifyRegistration() {
    setErrors({})
    const options = defaultOptions({
      method: 'POST',
      cache: 'no-store',
      json: { token: fields.token },
      credentials: 'include',
    })
    const result = await fetchJson(`${apiUri}/register/verify`, options)
    if (result.status === 400) setErrors(result.json?.errors || {})
    if (result.ok) {
      const token = result.json?.token
      setToken(token)
      navigate('/', { replace: true })
    } else {
      setMessage(result.json?.message)
    }
  }

  return (
    <>
      {message && (
        <div className="alert alert-danger">
          <code>{message}</code>
        </div>
      )}{' '}
      <VerifyForm
        fields={fields}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
      />
    </>
  )
}
