import { type Opportunity } from '../../types/Opportunity'
import { OppCard } from '../opp-card/OppCard'
import { useOppListingState } from '../opportunities/OppListingContextProvider'

type Props = {
  data: Opportunity[]
}
export const SearchResults = ({ data }: Props) => {
  const { activeId } = useOppListingState()
  if (data.length === 0) return <p>No results found.</p>

  return data.map(opportunity => (
    <OppCard
      isActive={activeId === opportunity.loadId}
      key={opportunity.loadId}
      opportunity={opportunity}
    />
  ))
}
