import LogRocket from 'logrocket'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import { appVersion, logrocketApp } from '@fv/client-core'

import { App } from './app/CarrierApp'

if (logrocketApp) {
  LogRocket.init(`d6lkaq/${logrocketApp}`, {
    rootHostname: 'freightview.com',
    release: appVersion,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
