import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { apiUri } from '@/constants'

import { type PublicShipment } from './types'

async function setPickupNumber(dto: {
  loadId: string
  pickupNumber: string
  token: string | null
}): Promise<PublicShipment> {
  if (!dto.token) throw new Error('No authentication token provided.')

  const pickupNumber = dto.pickupNumber.trim()
  if (!pickupNumber) throw new Error('Pickup number is required.')

  const endpoint = `${apiUri}/loads/${dto.loadId}/pickup-number`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      pickupNumber,
      token: dto.token,
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useSetPickupNumber(loadId: string, token: string | null) {
  const queryClient = useQueryClient()

  return useMutation(
    (pickupNumber: string) => setPickupNumber({ loadId, pickupNumber, token }),
    {
      onError: (e: { message: string }) => {
        toast.error(
          e?.message ??
            'Unable to save pickup number, please contact support@freightview.com for assistance.',
        )
      },
      onSuccess: shipment => {
        queryClient.setQueryData(['public', loadId, token], shipment)
        toast.success('Pickup number saved successfully.')
      },
    },
  )
}
