import { OppFilterPanel } from './FilterPanel'
import { OppListing } from './OppListing'
import { OppListingProvider } from './OppListingContextProvider'
import { OppMessaging } from './OppMessaging'
import { type oppPageStatusMap } from './types'

type Mode = (typeof oppPageStatusMap)['open'][number]

type Props = {
  mode?: Mode
}
export const OpenPage = ({ mode = 'open' }: Props) => {
  return (
    <OppListingProvider status={mode ?? 'open'} page="open">
      <OppFilterPanel
        items={[
          { label: 'Open opportunities', icon: 'pen', to: '/opportunities' },
          {
            label: 'Declined opportunities',
            icon: 'ban',
            to: '/opportunities/declined',
          },
        ]}
      />
      <OppListing emptyText="There are no opportunities in your list." />
      <OppMessaging />
    </OppListingProvider>
  )
}
