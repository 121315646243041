import { OppFilterPanel } from './FilterPanel'
import { OppListing } from './OppListing'
import { OppListingProvider } from './OppListingContextProvider'
import { OppMessaging } from './OppMessaging'
import { type oppPageStatusMap } from './types'

type Mode = (typeof oppPageStatusMap)['quoting'][number]
type Props = {
  mode?: Mode
}
export const QuotingPage = ({ mode }: Props) => {
  return (
    <OppListingProvider status={mode ?? 'offered'} page="quoting">
      <OppFilterPanel
        items={[
          { label: 'Active quotes', icon: 'dollar-sign', to: '/quoting' },
          { label: 'Lost quotes', icon: 'ban', to: '/quoting/lost' },
        ]}
      />
      <OppListing
        emptyText={
          mode === 'lost'
            ? 'You have not lost any opportunities.'
            : 'You are not quoting on any shipments.'
        }
      />
      <OppMessaging />
    </OppListingProvider>
  )
}
