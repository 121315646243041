import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import {
  FvButton,
  FvLinkButton,
  Icon,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { supportMessage } from '@/constants'

import { type Opportunity } from '../../types/Opportunity'
import { useEditConfirmationNumber } from './hooks/useEditConfirmationNumber'
import { LoadDetails } from './LoadDetails'
import { RefNumbers } from './RefNumbers'
import { StopList } from './StopList'

const confNumInputName = 'confirmationNumber'
type ShipmentDetailsProps = {
  opportunity: Opportunity
  listClassName?: string
}

export const ShipmentDetails = ({
  opportunity,
  listClassName,
}: ShipmentDetailsProps) => {
  const [isEditingConfNum, setEditingConfNum] = useState(false)
  const editConfirmation = useEditConfirmationNumber()
  const location = useLocation()

  const {
    bol,
    isArchived,
    isMultiTruck,
    loadId,
    locations,
    pickup,
    quoteRequestId,
    refNums,
    shipper,
    status,
  } = opportunity

  const canEditConfNum = status === 'confirmed' && !isArchived
  const confNum = pickup?.confirmationNumber ?? ''
  const isMultiStop = locations.length > 2
  const isViewingRelatedOpps = location.pathname.startsWith('/direct/')
  const refNumbers = refNums?.filter(n => n.value !== bol?.bolNumber)
  const showConfNum = Boolean(confNum) || canEditConfNum

  const onValidSubmit = (form: HTMLFormElement) => {
    if (editConfirmation.isLoading) return

    const nextConfNum =
      // @ts-expect-error valid use case
      form.elements[confNumInputName].value.trim()

    if (nextConfNum === confNum) {
      setEditingConfNum(false)
      return
    }

    editConfirmation
      .mutateAsync({
        confirmationNumber: nextConfNum,
        loadId,
      })
      .then(() => {
        setEditingConfNum(false)
      })
      .catch(() => {
        toast.error(`Unable to update confirmation number, ${supportMessage}`)
      })
  }

  let fromLabel = 'From'
  if (isMultiStop) fromLabel = 'Multi stop shipment from'
  if (isMultiTruck) fromLabel = 'Multi truck shipment from'

  return (
    <div
      className={clsx('shipment-item__information', {
        'shipment-item__information--multi-stop': isMultiStop,
      })}
    >
      <ul
        className={clsx('general-list general-list-fc-no-pad', listClassName)}
      >
        <li className="general-list__item flex-wrap leading-6 pr-10 relative">
          {/* mg mockup ticket 23686 - icon has to be a child for correct animation purposes */}
          {/* <Tooltip label="You have unread messages">
            <FvButton className="absolute top-1 right-2">
              <Icon icon="comment-alt-dots" className=" pulse-with-color" />
            </FvButton>
          </Tooltip> */}
          {/* end */}
          {(isMultiStop || isMultiTruck) && (
            <Icon
              icon={isMultiStop ? 'route-interstate' : 'layer-group'}
              className="fa-fw"
            />
          )}
          <span>
            {bol?.bolNumber && `BOL# ${bol.bolNumber} / `}
            {showConfNum && 'Confirmation# '}
            {showConfNum && canEditConfNum ? (
              <FvButton
                theme="underlined"
                onClick={() => setEditingConfNum(p => !p)}
              >
                {confNum || 'NA'}
              </FvButton>
            ) : (
              confNum
            )}
            {/* mg mockup ticket 23684 and 23685 showConfNum should be changed to correct state var */}
            {/* {showConfNum && ' / '}
            <span>
              Tracking#&nbsp;
              <FvButton
                theme="underlined"
                onClick={() => setEditingConfNum(p => !p)}
              >
                65589884
              </FvButton>
            </span>
            {showConfNum && ' / '}
            <span>
              Truck#&nbsp;
              <FvButton
                theme="underlined"
                onClick={() => setEditingConfNum(p => !p)}
              >
                34324
              </FvButton>
            </span>
            {showConfNum && ' / '}
            <span>
              Seal#&nbsp;
              <FvButton
                theme="underlined"
                onClick={() => setEditingConfNum(p => !p)}
              >
                <span>58888</span>
              </FvButton>{' '}
            </span> */}
            {/* end */}
            {showConfNum && ' / '}
            {'  '}
            {fromLabel}: {shipper.companyName}
            {status !== 'lost' && (
              <>
                {' '}
                <RefNumbers refNums={refNumbers} />
              </>
            )}
          </span>

          {isEditingConfNum && (
            <div className="edit-confirmation-ctn">
              <ValidatedForm
                className="flex items-center"
                onValidSubmit={onValidSubmit}
              >
                <TextField
                  autoFocus
                  className="form-control"
                  defaultValue={confNum}
                  name={confNumInputName}
                  readOnly={editConfirmation.isLoading}
                  required
                  type="text"
                />

                <FvButton
                  loading={editConfirmation.isLoading}
                  icon="check"
                  theme="default"
                  type="submit"
                  className="-ml-px"
                >
                  Update confirmation number
                </FvButton>
                <FvButton
                  icon="times"
                  onClick={() => setEditingConfNum(false)}
                  theme="plain"
                />
              </ValidatedForm>
            </div>
          )}
        </li>

        <StopList
          bolNum={bol?.bolNumber}
          locations={locations}
          showAddress={status === 'awarded'}
        />

        <LoadDetails load={opportunity} />
        {isMultiTruck && !isViewingRelatedOpps && (
          <li className="general-list__item">
            <FvLinkButton
              icon="eye"
              className="standard-link"
              iconClass="fa-fw"
              to={`/direct/${quoteRequestId}`}
            >
              View related opportunities
            </FvLinkButton>
          </li>
        )}
      </ul>
    </div>
  )
}
