import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import { type UpdateTrackStatusDTO } from '@fv/client-components'
import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { apiUri, supportMessage } from '@/constants'
import { useCacheHelpers } from '@/hooks/opportunities'

import { type Opportunity } from '../../../types/Opportunity'

async function updateTrackStatus(
  loadId: string,
  dto: UpdateTrackStatusDTO,
): Promise<Opportunity> {
  const { eventDate, eventTime, message, sequence } = dto
  const endpoint = `${apiUri}/loads/${loadId}/current-stop`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      sequence,
      ...(eventDate && { eventDate: dayjs(eventDate).format('YYYY-MM-DD') }),
      ...(eventTime && { eventTime }),
      ...(message?.trim() && { message: message.trim() }),
    }),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateTrackStatus(loadId: string) {
  const { updateCachedOpportunity } = useCacheHelpers()

  return useMutation(
    (dto: UpdateTrackStatusDTO) => updateTrackStatus(loadId, dto),
    {
      onSettled: (opportunity, error) => {
        if (error) {
          toast.error(`Unable to update tracking, ${supportMessage}`)
          return
        }

        if (opportunity) {
          updateCachedOpportunity({
            listKeys: ['confirmed'],
            opportunity,
          })
        }

        toast.success('Tracking updated successfully.')
      },
    },
  )
}
