import { useQuery } from '@tanstack/react-query'

import { type Message } from '@fv/models'
import { messagesQueryKeys } from '@/queryKeys'

import { carrierFetch } from '../../../utils/carrierFetch'

async function fetchMessages(loadId: string) {
  return carrierFetch<Message[]>(`/loads/${loadId}/messages`)
}

export function useMessages({ loadId }: { loadId: string }) {
  return useQuery(messagesQueryKeys.load(loadId), () => fetchMessages(loadId), {
    enabled: Boolean(loadId),
  })
}
