import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import toast, { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { BrowserCheck } from '@fv/client-components'
import { logoutSession } from '@fv/client-core'

import { Login } from './features/auth/Login'
import { SessionGate } from './features/auth/SessionGate'
import { ErrorBoundary } from './features/layout/ErrorBoundary'
import { PickupNumberForm } from './features/public/PickupNumberForm'
import { AuthenticatedApp } from './AuthenticatedApp'
import { apiUri } from './constants'

// Extend dayjs here for use throughout the app
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

const staleTime = 1000 * 60 * 2 // 2 minutes

function handleApiError(error: unknown) {
  const status = (error as { status?: number })?.status

  if (status === 401) {
    toast.error('Access denied, redirecting to login.', { id: 'unauthorized' })
    logoutSession(null, apiUri as string)
  }

  if (status === 403) {
    toast.error(
      'You do not have access to the requested data. Please contact customer support.',
      { id: 'forbidden' },
    )
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { onError: handleApiError },
    queries: {
      onError: handleApiError,
      staleTime,
      retry: (failCount, error) => {
        const status = (error as { status?: number })?.status
        if (status === 401 || status === 403) return false
        return failCount < 3
      },
    },
  },
})

export const App = () => {
  return (
    <BrowserCheck>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="login" element={<Login />}>
                <Route path=":registrationToken" element={null} />
              </Route>

              <Route path="pickup/:loadId" element={<PickupNumberForm />} />

              <Route
                path="*"
                element={
                  <SessionGate>
                    <AuthenticatedApp />
                  </SessionGate>
                }
              />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>

      <Toaster position="bottom-center" />
    </BrowserCheck>
  )
}
