import React, { type ErrorInfo, type PropsWithChildren } from 'react'

import { isModuleLoadError } from '@fv/client-core'

import { DefaultError } from './DefaultError'
import { ModuleLoadError } from './ModuleLoadError'

type Props = PropsWithChildren
type State = {
  hasError: boolean
  errorMessage: string | null
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = {
      hasError: false,
      errorMessage: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error.message)

    return {
      hasError: true,
      errorMessage: error.message,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(errorInfo?.componentStack)
  }

  render() {
    if (this.state.hasError) {
      const moduleError = isModuleLoadError(this.state.errorMessage)
      return (
        <div className="App">
          {!moduleError && <DefaultError error={this.state.errorMessage} />}
          {moduleError && <ModuleLoadError />}
        </div>
      )
    }

    return this.props.children
  }
}
