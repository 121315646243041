import { Link } from 'react-router-dom'

import { MessagingPanel } from '../messaging/MessagingPage'
import { PublicLayout } from './PublicLayout'

export const NotFoundPage = () => (
  <>
    <main>
      <PublicLayout>
        <h1>Page not found</h1>
        <p className="lead">
          The page you're looking for doesn't exist.{' '}
          <Link to="/">Try from here</Link>
        </p>
      </PublicLayout>
    </main>

    <MessagingPanel />
  </>
)
